/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import moment from 'moment';

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout},
  data() {
    return {
      title: "",
      firstname : "",
      lastname : "",
      email : "",
      password : "",
      currentpage : 1,
      selectedPackage : "",
      selectedStatus : "",
      optionsStatus : [
          {
              value : "",
              text : "All"
          },
          {
              value : 1,
              text : "Active"
          },
          {
              value : 0,
              text : "Suspend"
          }
      ],
    }
  },
  computed: {
    users () {
      return this.$store.getters["users/users"]
    },
    loading(){
        return this.$store.getters['users/loading'];
    },
    packages () {
      var packages = this.$store.getters["packages/packages"]
      var list = packages.list.map((v)=> {return {value:v._id,text:v.name_package}})
      return list
    },
  },
  created(){
  },
  mounted(){
    this.$store.dispatch('users/listUser')
    this.$store.dispatch('packages/listing')
  },
  methods : {
    parseDate(date){
        return moment(date).format('llll')
    },
    save(){
        if(this.firstname != ""){
            if(this.lastname != ""){
                if(this.email != ""){
                    if(this.selectedPackage != ""){
                        this.$store.dispatch('users/create',{
                            firstname : this.firstname,
                            lastname : this.lastname,
                            email : this.email,
                            password : this.password == "" ? "jaybod!@#$%" : this.password,
                            device : "Admin",
                            package_id : this.selectedPackage
                        })
                        .then((oke)=>{
                            this.$toast.open({
                                message: 'success',
                                type: 'success',
                            });
                            this.$router.push({ name: "users" });
                        })
                        .catch((notoke)=>{
                            this.$toast.open({
                                message: notoke,
                                type: 'error',
                            });
                        })
                    }else{
                        this.$toast.open({
                            message: 'packages not valid',
                            type: 'error',
                        });
                    }
                }else{
                    this.$toast.open({
                        message: 'email not valid',
                        type: 'error',
                    });
                }
            }else{
                this.$toast.open({
                    message: 'lastname not valid',
                    type: 'error',
                });
            }
        }else{
            this.$toast.open({
                message: 'firstname not valid',
                type: 'error',
            });
        }
    }
  }

};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Users </h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Tanggal : 05-Apr-2021 | Pukul : 03:35:34 , Selamat Malam</li>
            </ol>
          </div>
        </div>
        <div class="row justify-content-md-center" >
            <div class="col-md-6">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="" class="control-label">FirstName : </label>
                                <input type="text" class="form-control" placeholder="firstname" v-model="firstname">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">LastName : </label>
                                <input type="text" class="form-control" placeholder="lastname" v-model="lastname">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">Email : </label>
                                <input type="email" class="form-control" placeholder="email" v-model="email">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">Password : </label>
                                <input type="password" class="form-control" placeholder="email" v-model="password">
                            </div>
                            <div class="form-group">
                                <label for="">Packages : </label>
                                <b-form-select v-model="selectedPackage" :options="packages"></b-form-select>
                            </div>
                            <div class="form-group">
                                <router-link tag="a" to="/users" class=" mx-2 btn btn-danger btn-sm" v-b-tooltip.hover title="gk jadi ah, gk bayar sih"> <i class="bx bx-left-arrow-alt"></i> Back</router-link>
                                <button class="btn btn-info btn-sm mx-2" @click="save()" v-b-tooltip.hover title="ya, benar benar jalur orang dalam yah!"> <i class="bx bx-user-plus"></i> Create</button>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
